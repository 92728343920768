// -----------------------------------------------------------------------------
// Utilities - colors
// -----------------------------------------------------------------------------


// Descriptive color variables

$alabaster    : rgb(252, 252, 252);
$heavymetal   : rgb(31, 33, 30);
$curiousBlue  : rgb(41, 158, 212);
$ripeLemon    : rgb(248, 235, 33);
$pomegranate  : rgb(246, 41, 41);
$redViolet    : rgb(229, 22, 126);


// Functional color variables

$color__text--primary       : $heavymetal;
$color__text--secondary     : $alabaster;

$color__marker--first       : $curiousBlue;
$color__marker--second      : $ripeLemon;
$color__marker--third       : $pomegranate;

$color__background--primary : $alabaster;
$color__background--select  : $redViolet;
