// -----------------------------------------------------------------------------
// Components - marker
// -----------------------------------------------------------------------------


/**
 * Marker component
 *
 * Used in paragraphs for text marking in three different variants (marker--)
 * Variants (different backgrounds) are displayed with animate-underlay
 * Has an id=marker hook for triggering animate-underlay animation (with jQuery)
 *
 * 1. Contains child element .marker__text to unskewX content
 *
 */

 @keyframes animate-underlay {
   0% {width: 0; height: 100%;};
   100% {width: 100%; height: 100%;}
 }

 .animate-underlay::after {
  animation: animate-underlay;
 }

.marker {
 display: inline-block;
 position: relative;
 padding: 0 rem(5px);
 background-color: inherit;
 transform: skewX(-10deg);
}
/* set properties for Animation: animate-underlay - blueish background */
.marker--blue::after {
  position: absolute;
  content: '';
  left: 0;  /* animation starts from left */
  bottom: 0;
  width: 100%;
  height: 0;
  animation-duration: .9s;
  animation-delay: .3s;
  animation-fill-mode: forwards;
  background-color: $color__marker--first;
  z-index: -1;
};
/* set properties for Animation: animate-underlay - yellowish background  */
.marker--yellow::after {
  position: absolute;
  content: '';
  right: 0;  /* animation starts from right */
  bottom: 0;
  width: 100%;
  height: 0;
  animation-duration: .9s;
  animation-delay: .6s;
  animation-fill-mode: forwards;
  background-color: $color__marker--second;
  z-index: -1;
};
/* set properties for Animation: animate-underlay - redish background  */
.marker--red::after {
  position: absolute;
  content: '';
  left: 0;  /* animation starts from left */
  bottom: 0;
  width: 100%;
  height: 0;
  animation-duration: .9s;
  animation-delay: .9s;
  animation-fill-mode: forwards;
  background-color: $color__marker--third;
  z-index: -1;
};

.marker__text {  /* [1] */
 display: inline-block;
 transform: skewX(10deg);
}
