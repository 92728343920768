// -----------------------------------------------------------------------------
// Components - Logo
// -----------------------------------------------------------------------------

/**
 * Logo component
 *
 * Placed inside .footer (components/_logo.scss)
 */

 .logo {
   display: inline-block;
   width: rem(130px);
   height: rem(39px);
   margin-bottom: rem(10px);

   @include media('<medium') {
     @include img-background--plusHiDPI('logo-xxs', 'png');
   };


   @include media('>=medium') {
     height: rem(42px);
     width: rem(140px);

     @include img-background--plusHiDPI('logo-md', 'png');
   };
 }
