// -----------------------------------------------------------------------------
// Components - text
// -----------------------------------------------------------------------------


/**
 * Text component
 *
 * Used in sections as main description paragraph
 */

.text {
  max-width: rem(500px);
  margin-bottom: rem(50px);
  font-size: rem(16px);
  font-weight: $font-weight--light;

  @include media('>=small') {
    margin-left: rem(30px);
  };

  @include media('>=medium') {
    margin-left: rem(50px);
    font-size: rem(20px);
  };

  @include media('>=large') {
    margin-left: rem(70px);
  };

  @include media('>=x-large') {
    font-size: rem(24px);
  };
}
