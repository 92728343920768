// -----------------------------------------------------------------------------
// Components - google-map
// -----------------------------------------------------------------------------


/**
 * Goolge-map component
 *
 * Placed inside .map-section (layout/_map.section)
 */

.google-map {
  max-width: 100%;
  height: 100%;
}
