// -----------------------------------------------------------------------------
// Layout - containers
// -----------------------------------------------------------------------------


/**
 * Container layout
 *
 * Inner container with max-width
 */

.container {
  max-width: 94%;
  margin: 0 auto;

  @include media('>=small') {
    max-width: 90%;
  };

  @include media('>=medium') {
    max-width: 84%;
  };

  @include media('>=xx-large') {
    max-width: rem(1580px);
  };
}


/**
 * Container-full layout
 *
 * Full screen container
 */

.container-full {
  max-width: 100%;
}
