// -----------------------------------------------------------------------------
// Components - title
// -----------------------------------------------------------------------------


/**
 * Tile
 *
 * Used in sections with text content as main title
 *
 * 1. Starting from center, block is positioned on the right side of the screen
 * 2. Number is on the left side, positioned with negative margin (number width)
 */

.title {
  margin-left: 50%;  /* [1] */
  margin-bottom: rem(50px);
  display: inline-block;

  @include media('>=medium') {
    margin-bottom: rem(100px);
  };
}

.title__number {
  margin-left: rem(-25px);  /* [2] */
  font-size: rem(24px);
  font-weight: $font-weight--regular;

  @include media('>=medium') {
    margin-left: rem(-29px);
    font-size: rem(28px);
  };

  @include media('>=x-large') {
    margin-left: rem(-37px);
    font-size: rem(36px);
  };
};

.title__separation {
  display: block;
  width: rem(90px);
  height: 3px;
  margin-left: rem(-25px);
  transform: rotate(295deg);
  background-color: $color__marker--first;

  @include media('>=large') {
    width: rem(100px);
    margin-left: rem(-28px);
  };

  @include media('>=x-large') {
    width: rem(110px);
    margin-left: rem(-30px);
  };
};

.title__title {
  margin-left: rem(40px); /* width of separation element */
  font-size: rem(24px);
  font-weight: $font-weight--regular;

  @include media('>=medium') {
    margin-left: rem(45px);
    font-size: rem(28px);
  };

  @include media('>=x-large') {
    margin-left: rem(50px);
    font-size: rem(36px);
  };
}
