// -----------------------------------------------------------------------------
// Components - headline
// -----------------------------------------------------------------------------


/**
 * Headline
 *
 * Intro section headline
 * Fades-in on page start
 */

 @keyframes fadeIn {
   100% {opacity: 1;}
 }

.headline {
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  opacity: 0;
  font-size: rem(38px);
  font-weight: $font-weight--light;
  color: $color__text--secondary;
  animation: fadeIn;
  animation-delay: 2s;
  animation-duration: 3s;
  animation-fill-mode: forwards;

  span {
    font-weight: $font-weight--regular;
  }

  @include media('>=x-small') {
    font-size: rem(48px);
  };

  @include media('>=small') {
    font-size: rem(58px);
  };

  @include media('>=small', '<medium', 'landscape') {
    top: 28%;
  };

  @include media('>=medium') {
    font-size: rem(68px);
  };

  @include media('>=large') {
    font-size: rem(88px);
  };

  @include media('>=x-large') {
    font-size: rem(110px);
  };
}
