// -----------------------------------------------------------------------------
// Components - Arrows
// -----------------------------------------------------------------------------


/**
 * Arrow-down component
 *
 * Placed on bottom (center) of .intro-section (layout/_sections.scss)
 * It is a wrapper for svg icon embaded in html
 *
 * 1. Fades in on page load
 *
 * Links and scrolls to .about-section
 */

 @keyframes fadeIn {  /* [1] */
   100% {opacity: 1;}
 }

.arrow-down {
  display: inline-block;
  position: absolute;
  bottom: rem(40px);
  transform: translateX(-50%);
  width: rem(44px);
  height: rem(44px);
  fill: $color__text--primary;
  transition: fill .3s;
  animation-name: fadeIn;
  animation-delay: 2s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  opacity: 0;

  &:hover {
    fill: lighten($color__text--primary, 20%);
  };

  @include media('>=x-small') {
    width: rem(54px);
    height: rem(54px);
  };
}

/**
 * Arrow-up component
 *
 * Placed on bottom (center) of .footer (layout/_footer.scss)
 * It is a wrapper for svg icon embaded in html
 *
 * Links and scrolls to .header
 */

.arrow-up {
  display: inline-block;
  width: rem(44px);
  height: rem(44px);
  fill: $color__text--primary;
  transition: .3s;

  &:hover {
    fill: lighten($color__text--primary, 20%);
  };

  @include media('>=x-small') {
    width: rem(54px);
    height: rem(54px);
  };
}
