// -----------------------------------------------------------------------------
// Utilities - mixins
// -----------------------------------------------------------------------------


// Images
// -----------------------------------------------------------------------------

// Image as a background
//
// sets image as full background
// $img - type full image name without extension
// $img-atttach - type fixed for fixed background

@mixin img-background($img, $format, $bk-attach: scroll, $bk-size: cover) {
	background-image: url('#{$path--img}/#{$img}.#{$format}');
	background-repeat: no-repeat;
  background-position: center center;
  background-attachment: $bk-attach;
  background-size: $bk-size;
}

@mixin img-background--plusHiDPI($img, $format, $bk-attach: scroll, $bk-size: cover) {
  background-image: url('#{$path--img}/#{$img}.#{$format}');
	background-repeat: no-repeat;
  background-position: center center;
  background-attachment: $bk-attach;
  background-size: $bk-size;

  @media	only screen and (-webkit-min-device-pixel-ratio: 2),
          only screen and (min-resolution: 192dpi) {
            background-image: url('#{$path--img}/#{$img}@2x.#{$format}');
          	background-repeat: no-repeat;
            background-position: 50% 50%;
            background-attachment: $bk-attach;
            background-size: $bk-size;
          }
}
