// -----------------------------------------------------------------------------
// Components - nav
// -----------------------------------------------------------------------------

/**
 * Navigation
 *
 * Placed inside .header (_header.scss)
 * Contains only link to Kontakt section for direct skip to .contact-section
 */

.nav {
 position: relative;
 top: 50%;
 transform: translateY(-50%);
}

.nav__link {
 font-size: rem(16px);
 font-weight: $font-weight--light;
 color: lighten($color__text--primary, 20%);
 transition: .3s;

 &:hover {
   color: $color__text--primary;
 };

 @include media('>=small') {
   font-size: rem(18px);
 };

 @include media('>=medium') {
   font-size: rem(20px);
 };

 @include media('>=large') {
   font-size: rem(24px);
 };
}
