// -----------------------------------------------------------------------------
// Components - Contact
// -----------------------------------------------------------------------------

/**
 * Contact component
 *
 * Placed inside contact-section (layout/_sections.scss)
 *
 * Has an id=contact hook for triggering animate-underline animation (jQuery)
 * 1. Animaton underlines contact elements (made with ::after elemet)
 * 2. on hover background color is transitioned (made with ::before elemet)
 */

@keyframes animate-underline {
 0% {width: 0; height: 10%;};
 100% {width: 100%; height: 10%;}
}

.animate-underline::after {
  animation: animate-underline;
}

.contact {
  margin-top: rem(50px);
  margin-bottom: rem(50px);
  font-size: rem(16px);
  font-weight: $font-weight--light;

  @include media('>=medium') {
    font-size: rem(20px);
  };

  @include media('>=x-large') {
    margin-bottom: rem(100px);
    font-size: rem(24px);
  };
}

.contact__phone {
  display: block;
  position: relative;
  margin-left: rem(45px);
  margin-bottom: rem(30px);
  padding: rem(3px) rem(15px);

  /* element for transition background color from bottom to top (on hover) */
  &::before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    background-color: $color__marker--second;
    transform-origin: bottom;
    transition: height .3s ease;
    z-index:-1;
  };

  &:hover::before {  /* [1] */
    height: 100%;
  };

  /* [2] set element properties for Animation: animate-underline */
  &::after {
    position: absolute;
    content: '';
    right: 0;  /* animation starts from right */
    bottom: 0;
    width: 100%;
    height: 0%;
    animation-duration: .6s;
    animation-delay: .3s;
    animation-fill-mode: forwards;
    z-index:-1;
    background-color: $color__marker--second;
  };
};

.contact__mail {
  display: block;
  position: relative;
  max-width: rem(285px);
  margin-bottom: rem(40px);
  padding: rem(3px) rem(15px) rem(3px) rem(90px);
  background-color: none;
  transition: color .25s ease;

  &:hover {
    color: $color__text--secondary;
  }

  @include media('>=medium') {
    max-width: rem(330px);
  };

  @include media('>=x-large') {
    max-width: rem(375px);
  };

  /* element for transition background color from bottom to top (on hover) */
  &::before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    background-color: $color__marker--third;
    transform-origin: bottom;
    transition: height .3s ease;
    z-index:-1;
  };

  &:hover::before {  /* [1] */
    height: 100%;
  };

  /* [2] set element properties for Animation: animate-underline */
  &::after {
    position: absolute;
    content: '';
    left: 0;  /* animation starts from left */
    bottom: 0;
    width: 100%;
    height: 0%;
    animation-duration: .6s;
    animation-delay: .6s;
    animation-fill-mode: forwards;
    z-index: -1;
    background-color: $color__marker--third;
  };
};

.contact__social {
  display: block;
  position: relative;
  margin-left: rem(130px);
  padding: rem(3px) rem(15px) rem(3px) rem(15px);
  background-color: none;

  /* element for transition background color from bottom to top (on hover) */
  &::before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    background-color: $color__marker--first;
    transform-origin: bottom;
    transition: height .3s ease;
    z-index:-1;
  };

  &:hover::before {  /* [1] */
    height: 100%;
  };

  /* [2] set element properties for Animation: animate-underline */
  &::after {
    position: absolute;
    content: '';
    right: 0;  /* animation starts from right */
    bottom: 0;
    width: 100%;
    height: 0%;
    animation-duration: .9s;
    animation-delay: .9s;
    animation-fill-mode: forwards;
    z-index:-1;
    background-color: $color__marker--first;
  };
}
