// -----------------------------------------------------------------------------
// Layout - sections
// -----------------------------------------------------------------------------


/**
 * Intro section layout
 *
 * Width is set with .container (layout/_containers.scss)
 *
 * 1. Fades in on page load
 * Uses id=gradient for animated background gradient effect (scripts)
 *
 * Contains: .headline (componenets/_headline.scss) as main headline
 */

 @keyframes fadeIn {  /* [1] */
   100% {opacity: 1;}
 }

.intro-section {
  height: 87vh;  /* rest is set for header */
  text-align: center;
  background-color: $color__marker--first;
  animation-name: fadeIn;
  animation-delay: .5s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  opacity: 0;
  border-bottom: 20px solid $color__background--primary;
}


/**
 * About (O nama) section layout
 *
 * Width is set with .container (layout/_containers.scss)
 *
 * 1. .about-section__content is vertically centered with flexbox
 * 2. padding is set to avoid no-gap when vertical (flex) centering breaks
 *
 * Contains: .title (componenets/_title.scss)
 *           .text component (componenets/_text.scss)
 */

.about-section {
  display: flex;  /* [1] */
  min-height: 98vh;
  padding-top: rem(50px);  /* [2] */
}

.about-section__content {
  margin: auto;  /* [1] */
  flex-grow: 1;
}


/**
 * Showcase1 section layout
 *
 * Full width is set with .container-full (layout/_containers.scss)
 *
 * It has a full background image
 * On large screens image is fixed for parallax effect
 * 1. if js detect iOS device, with .is-ios attachment is set to scroll
 *
 * Image is overlayed with three empty overlay sections (set with grid)
 * Each overlay section has a different color
 */

 .is-ios .showcase-1-section {
   background-attachment: scroll !important;  /* [1] */
 }

.showcase-1-section {
  height: 100vh;

  @include media('<x-small') {
    @include img-background--plusHiDPI('showcase-1-portrait-xxs', 'jpg');
  };

  @include media('>=x-small', '<small') {
    @include img-background--plusHiDPI('showcase-1-portrait-xs', 'jpg');
  };

  @include media('>=small', '<medium') {
    @include img-background--plusHiDPI('showcase-1-portrait-sm', 'jpg');
  };

  @include media('>=small', '<medium', 'landscape') {
    @include img-background--plusHiDPI('showcase-1-sm', 'jpg');
  };

  @include media('>=medium', '<large') {
    @include img-background--plusHiDPI('showcase-1-portrait-md', 'jpg');
  };

  @include media('>=large', '<x-large') {
    @include img-background--plusHiDPI('showcase-1-lg', 'jpg', fixed);
  };

  @include media('>=large', '<x-large', 'portrait') {
    @include img-background--plusHiDPI('showcase-1-portrait-lg', 'jpg');
  };

  @include media('>=x-large', '<xx-large') {
    @include img-background--plusHiDPI('showcase-1-xl', 'jpg', fixed);
  };

  @include media('>=xx-large') {
    @include img-background--plusHiDPI('showcase-1-xxl', 'jpg', fixed)
  };
}

.showcase-1-section__overlay-1 {
  height: 100vh;
  background-color: rgba($color__marker--second, 0.5);
}

.showcase-1-section__overlay-2 {
  height: 100vh;
  border-left: 5px solid $color__background--primary;
  border-right: 5px solid $color__background--primary;
  background-color: rgba($color__marker--first, 0.5);
}

.showcase-1-section__overlay-3 {
  height: 100vh;
  background-color: rgba($color__marker--third, 0.5);
}


/**
 * Services (Usluge) section layout
 *
 * Width is set with .container (layout/_containers.scss)
 *
 * 1. .services-section__content is vertically centered with flexbox
 * 2. padding is set to avoid no-gap when vertical centering breaks
 *
 * Contains: .title (componenets/_title.scss)
 *           .subtitle (componenets/_subtitle.scss)
 *           .text (componenets/_text.scss)
 */

.services-section {
  display: flex;  /* [1] */
  min-height: 100vh;
  padding-top: rem(50px);  /* [2] */
}

.services-section__content {
  margin: auto;  /* [1] */
  flex-grow: 1;
}


/**
 * Showcase2 section layout
 *
 * Full width is set with .container-full (layout/_containers.scss)
 * Uses Grid for content layout (layout/_grid.scss)
 *
 * 1. Contains three showcase sections with full background images
 * 2. Each section with pseudo-element has a color overlay
 * 3. Showcase-1 and 2 share full screen height
 */

.showcase-2-section {
  min-height: 100vh;
}

.showcase-2-section__showcase-1 {  /* [1] */
  position: relative;
  width: 100%;
  height: 50vh;  /* [3] */
  border-bottom: 5px solid $color__background--primary;

  &::after {  /* [2] */
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color__marker--first, 0.5);
  };

  @include media('<x-small') {
    @include img-background--plusHiDPI('showcase-2-B-portrait-xxs', 'jpg');
  };

  @include media('>=x-small', '<small') {
    @include img-background--plusHiDPI('showcase-2-B-portrait-xs', 'jpg');
  };

  @include media('>=small', '<medium') {
    @include img-background--plusHiDPI('showcase-2-B-portrait-sm', 'jpg');
  };

  @include media('>=small', '<medium', 'landscape') {
    @include img-background--plusHiDPI('showcase-2-B-sm', 'jpg');
  };

  @include media('>=medium', '<large') {
    @include img-background--plusHiDPI('showcase-2-B-portrait-md', 'jpg');
  };

  @include media('>=large', '<x-large') {
    @include img-background--plusHiDPI('showcase-2-B-lg', 'jpg');
  };

  @include media('>=x-large', '<xx-large') {
    @include img-background--plusHiDPI('showcase-2-B-xl', 'jpg');
  };

  @include media('>=xx-large') {
    @include img-background--plusHiDPI('showcase-2-B-xxl', 'jpg')
  };
}

.showcase-2-section__showcase-2 {
  position: relative;
  width: 100%;
  height: 50vh;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color__marker--third, 0.5);
  };

  @include media('<x-small') {
    @include img-background--plusHiDPI('showcase-2-R-portrait-xxs', 'jpg');
  };

  @include media('>=x-small', '<small') {
    @include img-background--plusHiDPI('showcase-2-R-portrait-xs', 'jpg');
  };

  @include media('>=small', '<medium') {
    @include img-background--plusHiDPI('showcase-2-R-portrait-sm', 'jpg');
  };

  @include media('>=small', '<medium', 'landscape') {
    @include img-background--plusHiDPI('showcase-2-R-sm', 'jpg');
  };

  @include media('>=medium', '<large') {
    @include img-background--plusHiDPI('showcase-2-R-portrait-md', 'jpg');
  };

  @include media('>=large', '<x-large') {
    @include img-background--plusHiDPI('showcase-2-R-lg', 'jpg');
  };

  @include media('>=x-large', '<xx-large') {
    @include img-background--plusHiDPI('showcase-2-R-xl', 'jpg');
  };

  @include media('>=xx-large') {
    @include img-background--plusHiDPI('showcase-2-R-xxl', 'jpg')
  };
}

.showcase-2-section__showcase-3 {
  position: relative;
  // border-top: 5px solid $color__background--primary;

  @include media('>=small') {
    width: 100%;
    height: 100vh;
    border-top: 0;
    border-left: 5px solid $color__background--primary;
  };

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color__marker--second, 0.5);
  };

  @include media('>=small', '<medium') {
    @include img-background--plusHiDPI('showcase-2-Y-sm', 'jpg');
  };

  @include media('>=medium', '<large') {
    @include img-background--plusHiDPI('showcase-2-Y-md', 'jpg');
  };

  @include media('>=large', '<x-large') {
    @include img-background--plusHiDPI('showcase-2-Y-lg', 'jpg');
  };

  @include media('>=x-large', '<xx-large') {
    @include img-background--plusHiDPI('showcase-2-Y-xl', 'jpg');
  };

  @include media('>=xx-large') {
    @include img-background--plusHiDPI('showcase-2-Y-xxl', 'jpg')
  };
}


/**
 * Contact (Kontakt) section layout
 *
 * Width is set with .container (layout/_containers.scss)
 *
 * 1. .services-section__content is vertically centered with flexbox
 * 2. padding is set to avoid no-gap when vertical centering breaks
 *
 * Contains: .title (componenets/_title.scss)
 *           .text (componenets/_text.scss)
 *           .address (componenets/_address.scss)
 *           .contact (componenets/_contact.scss)
 *           .map-pointer (componenets/_map-pointer.scss)
 */

.contact-section {
  display: flex;  /* [1] */
  min-height: 100vh;
  padding-top: rem(50px);  /* [2] */
  padding-bottom: rem(50px);
}

.contact-section__content {
  margin: auto;  /* [1] */
  flex-grow: 1;
}


/**
 * Map section layout
 *
 * Width is set with .container (layout/_containers.scss)
 *
 * Contains: .google-map (componenets/_google-map.scss)
 */

.map-section {
  height: 70vh;
  padding-top: rem(25px);
}
