// -----------------------------------------------------------------------------
// Base - base
// -----------------------------------------------------------------------------


/**
 * HTML basic setup
 *
 * 1. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom:
 *    https://necolas.github.io/normalize.css/
 */
html {
  font-size: $base__font-size;
  -ms-text-size-adjust: 100%; /* [1] */
  -webkit-text-size-adjust: 100%; /* [1] */
  background-color: $color__background--primary;
  box-sizing: border-box;
  height: 100%;
}

/**
 * Inherit box-sizing from the parent elements
 *
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: border-box;
}

/**
 * Reset - Customed
 *
 * http://meyerweb.com/eric/tools/css/reset/
 */
body,
h1,
h2,
h3,
p {
	margin: 0;
	padding: 0;
}

/**
 * Body basic setup
 */
body {
  height: 100%;
	color: $color__text--primary;
  font-family: $font-family--primary;
  font-size: 100%;
  font-style: italic;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: $base__line;
}

/**
 * HTML5 display setup for older browsers
 *
 * http://meyerweb.com/eric/tools/css/reset/
 */
footer,
header,
main,
menu,
nav,
section {
  display: block;
}

/**
 * Video setup
 *
 * Correct `inline-block` display not defined in IE 8/9.
 *
 * https://necolas.github.io/normalize.css/
 */
video {
  display: inline-block;
}

/**
 * Images setup
 *
 * 1. Set images for responsive friendly
 * 2. Remove the border on images inside links in IE 10-.
 *    https://necolas.github.io/normalize.css/
 */
img {
  max-width: 100%; /* [1] */
  height: auto; /* [1] */
  border: 0; /* [2] */
}

/**
 * Remove the gap between these elements and the bottom of their containers
 *
 * https://github.com/h5bp/html5-boilerplate/issues/440
 * Borrowed from HTML5 Boilerplate
 */
img,
svg,
video {
    vertical-align: middle;
}

/**
 * Hide the overflow in IE.
 *
 * https://necolas.github.io/normalize.css/
 */
svg:not(:root) {
  overflow: hidden;
}

/**
 * Remove text-shadow and set color on selection
 *
 * https://twitter.com/miketaylr/status/12228805301
 */
::selection {
  background: $color__background--select;
  text-shadow: none;
}

/**
 * Clear links
 */
a {
  color: inherit;
  background-color: transparent;
	text-decoration: none;
}


/**
 * Strong default setup
 */

strong {
  font-weight: $font-weight--regular;
}
