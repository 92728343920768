// -----------------------------------------------------------------------------
// Components - Address
// -----------------------------------------------------------------------------

/**
 * Address component
 *
 * Placed inside .contact-section (layout/_sections.scss)
 */

.address {
  margin-left: rem(50px);
};

.address__title {
  margin-bottom: rem(10px);
  font-size: rem(18px);
  font-weight: $font-weight--regular;

  @include media('>=medium') {
    font-size: rem(22px);
  };

  @include media('>=x-large') {
    font-size: rem(24px);
  };
}

.address__address {
  margin-left: rem(20px);
  font-size: rem(16px);
  font-weight: $font-weight--light;

  @include media('>=medium') {
    font-size: rem(20px);
  };

  @include media('>=x-large') {
    font-size: rem(24px);
  };
}
