// -----------------------------------------------------------------------------
// Utilities - typography
// -----------------------------------------------------------------------------


// Font weights

$font-weight--light   : 300;
$font-weight--regular : 400;


// Font Stacks

$font-family--fallback  : 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

$font-family--primary   : 'Roboto', $font-family--fallback;
$font-family--secondary : $font-family--fallback;


// Text

$base__font-size : 1rem;
$base__line      : 1.4;
$letter-space    : 1px;
