// -----------------------------------------------------------------------------
// Layout - footer
// -----------------------------------------------------------------------------


/**
 * Footer layout
 *
 * Width is set with .container (layout/_containers.scss)
 *
 * 1. .about-section__content is vertically centered with flexbox
 * 2. all paragraph/text properties are set with .footer__content element
 *
 * Contains: paragraph with copyright, firm and credits informaition
 *           .arrow-up (components/_arrow-up.scss)
 */

.footer {
  min-height: 30vh;
  padding-top: rem(20px);
  display: flex;  /* [1] */
}

.footer__content {  /* [2] */
  max-width: rem(520px);
  margin: auto;  /* [1] */
  text-align: center;
  font-size: rem(9px);
  font-weight: $font-weight--light;

  @include media('>=small') {
    max-width: rem(690px);
    font-size: rem(12px);
  };
}
