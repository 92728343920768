// -----------------------------------------------------------------------------
// Layout - grid
// -----------------------------------------------------------------------------


/**
 * Grid
 *
 * Small flexbox grid setup for this page
 *
 * 1. Used for positioning as content is usualy aligned to right
 */

.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.row--flex-end {
  justify-content: flex-end;  /* [1] */
}

/* xs - from 0 */
.col-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

/* sm - from 478px */
@include media('>=small') {
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
};

/* md - from 768px */
@include media('>=medium') {
  .col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
};

/* md - from 992px */
@include media('>=large') {
  .col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
};
