// -----------------------------------------------------------------------------
// Components - subtitle
// -----------------------------------------------------------------------------


/**
 * Subtitle
 *
 * Used in Services section as title for different services
 *
 * Holds id=subtitle for triggering .animate-triangle on .subtitle__triangle
 * Contains triangle element (with three variants)
 */

@keyframes animate-triangle {
  0% {
    border-top: 0;
  };
  100% {
    border-right: rem(40px) solid $color__background--primary;
    border-top: 100% solid inherit;  /* inherits triangle variants color */
  };
}

@include media('>=medium') {
  @keyframes animate-triangle {
    0% {
      border-top: 0;
    };
    100% {
      border-right: rem(50px) solid $color__background--primary;
      border-top: 100% solid inherit;  /* inherits triangle variants color */
    };
  }
};

.animate-triangle {
  animation-name: animate-triangle;
  animation-duration: .6s;
  animation-delay: .6s;
  animation-fill-mode: forwards;
}

.subtitle {
  position: relative;
  margin-bottom: rem(20px);
  height: rem(40px);
  width: rem(135px);

  @include media('>=medium') {
    height: rem(50px);
    margin-left: rem(40px);
  };
}

.subtitle__triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-right: rem(0px) solid $color__background--primary;

  @include media('>=medium') {
    border-right: rem(0px) solid $color__background--primary;
  };
}

.subtitle__triangle--blue {
  border-top: rem(40px) solid $color__marker--first;

  @include media('>=medium') {
    border-top: rem(50px) solid $color__marker--first;
  };
}

.subtitle__triangle--yellow {
  border-top: rem(40px) solid $color__marker--second;

  @include media('>=medium') {
    border-top: rem(50px) solid $color__marker--second;
  };
}

.subtitle__triangle--red {
  border-top: rem(40px) solid $color__marker--third;

  @include media('>=medium') {
    border-top: rem(50px) solid $color__marker--third;
  };
}

.subtitle__title {
  position: absolute;
  font-size: rem(20px);
  font-weight: $font-weight--regular;
  bottom: rem(5px);
  left: rem(10px);

  @include media('>=medium') {
    font-size: rem(24px);
  };

  @include media('>=x-large') {
    font-size: rem(30px);
  };
}
