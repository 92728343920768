// -----------------------------------------------------------------------------
// Layout - header
// -----------------------------------------------------------------------------


/**
 * Header layout
 *
 * Contains .nav (components/_nav.scss)
 */

 .header {
   height: 13vh;
   max-width: 100%;
   background-color: $color__background--primary;
   text-align: center;
 }
