// -----------------------------------------------------------------------------
// Components - map-pointer
// -----------------------------------------------------------------------------


/**
 * Map pointer component
 *
 * Placed on bottom (center) of .contact-section (layout/_sections.scss)
 * It is a wrapper for svg icon embaded in html
 *
 * It points to the next section where google map is displayed
 * With link scrolls to .map-section
 *
 * 1. Contains jumping animation for infinite up and down movement
 * 2. On hover svg changes color (fill)
 */

@keyframes jumping {  /* [1] */
  0% {transform: translateY(0);};
  50% {transform: translateY(3px);};
  100% {transform: translateY(0);};
}

.map-pointer {
  display: block;
  position: relative;
  width: rem(44px);
  height: rem(44px);
  margin-left: rem(-32px);
  left: 50%;
  fill: $color__text--primary;
  transition: .3s;
  animation: jumping;  /* [1] */
  animation-duration: 1.3s;
  animation-iteration-count: infinite;

  &:hover {
    fill: lighten($color__text--primary, 20%);  /* [2] */
  };

  @include media('>=x-small') {
    width: rem(54px);
    height: rem(54px);
  };

  @include media('>=large') {
    width: rem(64px);
    height: rem(64px);
  };
}
